<template>
  <div class="show-error-success">
    <div class="p-3 mb-2 bg-success text-white" v-if="msgSucess">Success</div>
    <div class="p-3 mb-2 bg-danger-subtle text-emphasis-danger" v-if="msgError">
      {{ message }}
    </div>
    <div class="p-3 mb-2 bg-danger-subtle text-emphasis-danger" v-if="msgRemplir">
      veuillez mettre un email
    </div>
  </div>
  <div class="seePasswordChange" v-if="toogleReset">
    <section class="py-5 position-relative">
      <span
        @click.prevent="toogleReset = !toogleReset"
        class="p-3 position-absolute text-light fw-bold"
        >close</span
      >
      <input
        class="mt-2"
        type="email"
        placeholder="email"
        v-model="emailReset"
      />
      <button
        class="btn-lg bg-warning text-primary w-100 p-4 fw-bold mt-4 border-0"
        @click.prevent="resetPassword"
      >
        <span v-if="!spinnerReset">Reset password</span>
        <span v-else class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </span>
      </button>
    </section>
  </div>
  <form>
    <img src="/iugb.jpg" class="w-50" alt="iugb" />
    <section>
      <h1 class="p-3 text-light">LOG IN</h1>
      <div class="mb-3">
        <input
          type="email"
          placeholder="email"
          autocomplete="on"
          v-model="email"
        />
      </div>
      <div>
        <input type="Password" placeholder="Password" v-model="password" />
      </div>
      <button
        class="btn bg-warning text-primary fw-bold mt-3 w-100 p-3"
        @click.prevent="handleConnect"
      >
        <span v-if="!spinner">Sign In</span>
        <span v-if="spinner" class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </span>
      </button>
      <div>
        <button
          class="btn bg-primary text-warning fw-bold mt-3 w-100 p-3"
          @click.prevent="toogleReset = true"
        >
          <span>Password Forgot</span>
        </button>
      </div>
      <div class="p-3">
        <strong class="text-orange">IUGB-CATERING@2022</strong>
      </div>
    </section>
  </form>
</template>
<script>
import instanceAxios from "../api/api";
export default {
  name: "LogIn",
  data() {
    return {
      email: "",
      toogleReset:false,
      msgSucess:false,
      password: "",
      msgError:false,
      message:"",
      spinner:false,
      spinnerReset:false,
      msgRemplir:false,
    };
  },
  methods: {
    handleError(msg){
      this.msgError = !this.msgError
      this.message = msg.response.data.message

    },
    async handleConnect() {
      console.log(this.email, this.password);
      let data = {
        email:this.email,
        password:this.password
      }
      this.spinner = true
      if(!this.email || !this.password){
         console.log("remplir")
      }
      try{
        const response = await instanceAxios.post("auth/login",data)
        console.log(response)
        if(!response.data.status){
          this.handleError(response.data.message)
          this.spinner = false
        }
        this.msgError = false
        this.msgSucess = true
      
          this.spinner = false
        localStorage.setItem("user",JSON.stringify(response.data.user))
        localStorage.setItem("token",JSON.stringify(response.data.access_token))
        this.$router.push("/accueil");
        this.password = "";
        this.email = "";

      }catch(error){
        console.log(error)
        this.handleError(error)
        this.msgError = true
        this.spinner = false
      }




    },
    async resetPassword(){

      try{
        if(!this.emailReset){
          this.msgRemplir = !this.msgRemplir
          setTimeout(()=>{
            this.msgRemplir = false
          },1000)
        }else{
          this.spinnerReset = true
          const response = await instanceAxios.post("password-forgot",{
email:this.emailReset
        })
      
        console.log("dhfh",response.data.state)
        if(response.data.state === true){
         this.msgSucess = true
         this.spinnerReset = false
         setTimeout(()=>{
          this.msgSucess = false
         },1000)
        //  localStorage.removeItem("reset")
        }
        this.spinnerReset = false
        this.emailReset = ""
      }
        }catch(error){
        console.log(error)
      }

    }
  },
};
</script>
<style scoped>
.seePasswordChange span {
  z-index: 20;
  top: 0;
  right: 0;
}
.seePasswordChange {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
  background: rgba(0, 0, 0, 0.383);
  z-index: 20;
  display: flex;
  place-items: flex-end;
  justify-content: flex-end;
}

.text-orange {
  color: orange;
  font-size: 0.5em;
}
form {
  height: auto;
}
section {
  width: 100%;
  height: auto;
  margin: 0 auto;
  transform: translateY(15%);
  background: rgb(0, 0, 120);
  padding: 2em 1em 4em 1em;
  border-radius: 40px 40px 0 0;
}
input {
  width: 100%;
  padding: 1em;
}
</style>
