import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/LogIn.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import( '../views/ProfilView.vue')
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import( '../views/showMenu.vue')
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import( '../views/ResetPassword.vue')
  },
  {
    path: '/accueil',
    name: 'Home',
    component: () => import( '../views/HomeView.vue'),
    meta:{
      requiresAuth:true
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth) {
    const session = JSON.parse(localStorage.getItem("user"));

    if (session.first_connection === 0) {
      next({
        path: "/reset",
      });
    }
    if (session.first_connection === 1) {
      next();
    }
  } else {
    next();
  }
});

export default router
