import { createStore } from "vuex";

export default createStore({
  state: {
    qrcode: null,
  },
  getters: {},
  mutations: {
    CHANGE_QRCODE(state, codeqr) {
      state.qrcode = codeqr;
    },
  },

  actions: {},

  modules: {},
});
